import React, { useState, useRef, useEffect } from "react";
import { Play, Pause, SkipForward, SkipBack } from "lucide-react";
import { songItems } from "./folders/SongFolder";
import { useAudio } from "../context/AudioContext";

const TopAudioPlayer: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [showTitle, setShowTitle] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const { setProgress } = useAudio();

  // We'll store the analyser and audio context in state.
  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  // This ref is used to ensure that we create the MediaElementSource only once.
  const mediaSourceRef = useRef<MediaElementAudioSourceNode | null>(null);

  const currentSong = songItems[currentSongIndex];

  useEffect(() => {
    // Run this effect only once after the component is mounted.
    if (audioRef.current && !mediaSourceRef.current) {
      const context = new AudioContext();
      setAudioContext(context);
      try {
        // Create the media element source only once.
        const source = context.createMediaElementSource(audioRef.current);
        mediaSourceRef.current = source;

        // Create and configure the analyser node.
        const analyserNode = context.createAnalyser();
        analyserNode.fftSize = 64;
        source.connect(analyserNode);
        analyserNode.connect(context.destination);
        setAnalyser(analyserNode);
      } catch (error) {
        console.error("Error creating Media Element Source:", error);
      }
    }
    // Empty dependency array: this effect should run only once.
  }, []);

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        // Resume the AudioContext if it is suspended (required by some browsers).
        if (audioContext && audioContext.state === "suspended") {
          audioContext.resume();
        }
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
      setShowTitle(true);
    }
  };

  const nextSong = () => {
    setCurrentSongIndex((prevIndex) => (prevIndex + 1) % songItems.length);
    setShowTitle(true);
    setProgress(0); // Reset progress when changing songs
    if (isPlaying && audioRef.current) {
      setTimeout(() => {
        audioRef.current?.play();
      }, 100);
    }
  };

  const prevSong = () => {
    setCurrentSongIndex((prevIndex) =>
      prevIndex === 0 ? songItems.length - 1 : prevIndex - 1,
    );
    setShowTitle(true);
    setProgress(0);
    if (isPlaying && audioRef.current) {
      setTimeout(() => {
        audioRef.current?.play();
      }, 100);
    }
  };

  const updateProgress = () => {
    if (audioRef.current) {
      const value =
        audioRef.current.currentTime / (audioRef.current.duration || 1);
      setProgress(value);
    }
  };

  // Pass the analyser and isPlaying state to App.tsx through a global variable
  // This is a workaround to avoid restructuring the entire app
  useEffect(() => {
    // @ts-ignore - Adding properties to window
    window.audioVisualizerProps = {
      analyser,
      isPlaying,
    };
  }, [analyser, isPlaying]);

  return (
    <div className="top-audio-player">
      <div className="player-controls">
        <button onClick={prevSong} className="player-button">
          <SkipBack size={16} />
        </button>
        <button onClick={togglePlay} className="player-button">
          {isPlaying ? <Pause size={16} /> : <Play size={16} />}
        </button>
        <button onClick={nextSong} className="player-button">
          <SkipForward size={16} />
        </button>
      </div>

      {showTitle && (
        <div className="song-title">
          {currentSong.name.replace(".mp3", "").replace(/_/g, " ")}
        </div>
      )}

      <audio
        ref={audioRef}
        src={currentSong.audioUrl}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onTimeUpdate={updateProgress}
      />
    </div>
  );
};

export default TopAudioPlayer;
