import { BaseItem } from "../../types";

export const educationItems: BaseItem[] = [
  {
    name: "MATH & COGNITIVE SCIENCE, VANDERBILT UNIVERSITY",
    size: "∞",
    dateAdded: "08.2019 - 05.2023",
    kind: "DEGREE",
    font: "Supreme",
  },
  {
    name: "Study Abroad, Madrid",
    size: "∞",
    dateAdded: "01.2022 - 05.2022",
    kind: "EXPERIENCE",
    font: "Ceratyn",
  },
];
