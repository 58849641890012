import React, { useEffect, useRef } from "react";
import * as THREE from "three";

interface AudioVisualizerProps {
  analyser: AnalyserNode;
  isPlaying: boolean;
}

const AudioVisualizer: React.FC<AudioVisualizerProps> = ({
  analyser,
  isPlaying,
}) => {
  const mountRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mountRef.current) return;
    let frameId: number;

    // Set up a Three.js scene with an orthographic camera
    const scene = new THREE.Scene();
    const camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0); // Transparent background

    mountRef.current.appendChild(renderer.domElement);

    // Load the OVO owl texture
    const textureLoader = new THREE.TextureLoader();
    const owlTexture = textureLoader.load("/content/ovo_owl.png");

    // Create a full-screen plane
    const geometry = new THREE.PlaneGeometry(4, 4);
    const material = new THREE.ShaderMaterial({
      uniforms: {
        uTime: { value: 0.0 },
        uIntensity: { value: 0.0 },
        uBassIntensity: { value: 0.0 },
        uMidIntensity: { value: 0.0 },
        uHighIntensity: { value: 0.0 },
        uBassImpact: { value: 0.0 }, // For bass impact
        uHighImpact: { value: 0.0 }, // For high frequency impact
        uResolution: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
        uTexture: { value: owlTexture },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = vec4(position, 1.0);
        }
      `,

      fragmentShader: `
        uniform float uTime;
        uniform float uIntensity;
        uniform float uBassIntensity;
        uniform float uMidIntensity;
        uniform float uHighIntensity;
        uniform float uBassImpact;    // Immediate bass impact for sharp movements
        uniform float uHighImpact;    // Immediate high frequency impact
        uniform vec2 uResolution;
        uniform sampler2D uTexture;
        varying vec2 vUv;
        
        #define PI 3.14159265359
        
        // Properly centered UV coordinates
        vec2 getCenteredUV(vec2 uv) {
          float aspect = uResolution.x / uResolution.y;
          
          // Center point is always 0.5, 0.5 in UV space
          vec2 center = vec2(0.5);
          
          // Scale UVs to maintain aspect ratio
          vec2 scaledUV = uv;
          
          if (aspect > 1.0) {
            // Landscape orientation
            scaledUV.x = (uv.x - 0.5) / aspect + 0.5;
          } else {
            // Portrait orientation
            scaledUV.y = (uv.y - 0.5) * aspect + 0.5;
          }
          
          return scaledUV;
        }
        
        // Improved edge detection function
        float detectEdge(sampler2D tex, vec2 uv, float threshold) {
          float eps = 0.002; // Increased for cleaner edges
          
          // Sample the texture at the current UV and at 8 neighboring points for better edge detection
          vec4 center = texture2D(tex, uv);
          vec4 left = texture2D(tex, uv + vec2(-eps, 0.0));
          vec4 right = texture2D(tex, uv + vec2(eps, 0.0));
          vec4 top = texture2D(tex, uv + vec2(0.0, -eps));
          vec4 bottom = texture2D(tex, uv + vec2(0.0, eps));
          vec4 topLeft = texture2D(tex, uv + vec2(-eps, -eps));
          vec4 topRight = texture2D(tex, uv + vec2(eps, -eps));
          vec4 bottomLeft = texture2D(tex, uv + vec2(-eps, eps));
          vec4 bottomRight = texture2D(tex, uv + vec2(eps, eps));
          
          // Calculate differences with all neighbors
          float diff = length(center - left) + length(center - right) + 
                      length(center - top) + length(center - bottom) +
                      length(center - topLeft) + length(center - topRight) +
                      length(center - bottomLeft) + length(center - bottomRight);
          
          // Normalize and apply threshold
          diff = diff / 8.0;
          
          // Only show edges where the original image has some opacity
          return step(threshold, diff) * smoothstep(0.1, 0.2, center.a);
        }
        
        // Color palette function
        vec3 palette(float t) {
          // Create a smooth color palette that cycles through colors
          vec3 a = vec3(0.8, 0.5, 0.4);  // Controls the base colors
          vec3 b = vec3(0.2, 0.4, 0.2);  // Controls the color spread
          vec3 c = vec3(2.0, 1.0, 1.0);  // Controls the frequency of each color channel
          vec3 d = vec3(0.0, 0.25, 0.25); // Controls the phase of each color channel
          
          return a + b * cos(2.0 * PI * (c * t + d));
        }
        
        // Noise function for more organic movement
        float noise(vec2 p) {
          vec2 ip = floor(p);
          vec2 u = fract(p);
          u = u * u * (3.0 - 2.0 * u);
          
          float res = mix(
            mix(sin(dot(ip, vec2(13.9898, 8.233)) * 43758.5453),
                sin(dot(ip + vec2(1.0, 0.0), vec2(13.9898, 8.233)) * 43758.5453), u.x),
            mix(sin(dot(ip + vec2(0.0, 1.0), vec2(13.9898, 8.233)) * 43758.5453),
                sin(dot(ip + vec2(1.0, 1.0), vec2(13.9898, 8.233)) * 43758.5453), u.x), u.y);
          return 0.5 + 0.5 * res;
        }
        
        void main() {
          // Get properly centered coordinates
          vec2 centeredUV = getCenteredUV(vUv);
          
          // Base scale for the owl
          float scale = 0.25;
          
          // Create a continuous bouncing motion that's enhanced by bass
          float baseBounce = sin(uTime * 1.5) * 0.01; // Small continuous bounce
          float bassBoost = uBassImpact * 0.05; // Bass impact boosts the bounce
          float verticalBounce = baseBounce + bassBoost;
          
          // Scale effect from bass - owl grows with bass hits
          float scaleEffect = 1.0 + uBassIntensity * 0.1 + uBassImpact * 0.05;
          float dynamicScale = scale / scaleEffect; // Smaller value = larger owl
          
          // Apply vertical bounce and scale to UVs
          vec2 bouncedUV = centeredUV;
          bouncedUV.y += verticalBounce; // Apply vertical bounce
          
          // Scale from center
          vec2 scaledUV = (bouncedUV - 0.5) / dynamicScale + 0.5;
          
          // Only process if we're within the scaled UV range
          if (scaledUV.x < 0.0 || scaledUV.x > 1.0 || scaledUV.y < 0.0 || scaledUV.y > 1.0) {
            gl_FragColor = vec4(0.0);
            return;
          }
          
          // Add horizontal sway based on time and enhanced by bass
          float sway = sin(uTime * 0.8) * 0.01; // Base sway
          sway += uBassIntensity * sin(uTime * 1.2) * 0.015; // Bass-enhanced sway
          scaledUV.x += sway;
          
          // Add high-frequency jitter - small rapid movements for treble
          float jitterAmount = 0.002 * uHighImpact; // Base amount
          jitterAmount += 0.001 * uHighIntensity; // Continuous small amount
          
          // Apply jitter with different frequencies for x and y
          scaledUV.x += jitterAmount * sin(uTime * 20.0);
          scaledUV.y += jitterAmount * cos(uTime * 18.0);
          
          // Create a flowing distortion effect that responds to mid frequencies
          float flowSpeed = 0.5; // Base flow speed
          float flowStrength = 0.005 + uMidIntensity * 0.01; // Strength based on mids
          
          // Create organic flowing distortion using noise
          float noiseTime = uTime * flowSpeed;
          float noiseScale = 4.0;
          float distX = noise(vec2(scaledUV.y * noiseScale, noiseTime)) - 0.5;
          float distY = noise(vec2(scaledUV.x * noiseScale, noiseTime + 100.0)) - 0.5;
          
          // Apply the flow distortion
          vec2 flowUV = scaledUV + vec2(distX, distY) * flowStrength;
          
          // Detect edges in the texture
          float edge = detectEdge(uTexture, flowUV, 0.15);
          
          // Create a secondary glow effect that responds to mid frequencies
          float glowRadius = 0.003 * (1.0 + uMidIntensity);
          float glow = 0.0;
          
          // Sample multiple points for the glow
          for (int i = 0; i < 8; i++) {
            float angle = float(i) * PI / 4.0;
            vec2 offset = vec2(cos(angle), sin(angle)) * glowRadius;
            glow += detectEdge(uTexture, flowUV + offset, 0.15) * 0.5;
          }
          
          // Combine edge and glow
          float finalEdge = edge + glow * 0.5 * uMidIntensity;
          
          // Create a dynamic color palette based on audio frequencies
          float colorCycle = uTime * 0.05; // Slow color cycling
          float colorShift = uMidIntensity * 0.3 + uHighIntensity * 0.4;
          
          // Base color is gold
          vec3 baseGold = vec3(0.83, 0.68, 0.21);
          
          // Create a dynamic color that shifts between gold and other colors based on audio
          vec3 dynamicColor = mix(
            baseGold,
            palette(colorCycle + colorShift),
            uMidIntensity * 0.6 + uHighIntensity * 0.4
          );
          
          // Add iridescence that responds to high frequencies
          float iridescence = sin(flowUV.x * 10.0 + flowUV.y * 8.0 + uTime * (1.0 + uHighIntensity * 2.0)) * 0.5 + 0.5;
          vec3 iridColor = palette(iridescence * 0.2 + colorCycle);
          
          // Mix in iridescence more strongly with high frequencies
          vec3 finalColor = mix(dynamicColor, iridColor, uHighIntensity * 0.5 * iridescence);
          
          // Add inner glow with a different color that responds to mid frequencies
          vec3 innerGlowColor = palette(colorCycle + 0.5);
          finalColor = mix(finalColor, innerGlowColor, glow * uMidIntensity * 0.5);
          
          // Pulse the opacity with the beat
          float baseOpacity = 0.7 + uBassIntensity * 0.3;
          float breathingEffect = 0.9 + 0.1 * sin(uTime * 0.8); // Subtle breathing effect
          
          // Make opacity responsive to overall intensity
          float alpha = finalEdge * baseOpacity * breathingEffect * (0.5 + uIntensity * 0.5);
          
          // Output final color
          gl_FragColor = vec4(finalColor, alpha);
        }
      `,
      transparent: true,
    });

    const plane = new THREE.Mesh(geometry, material);
    scene.add(plane);

    // Prepare arrays to hold frequency data from the analyser
    const frequencyData = new Uint8Array(analyser.frequencyBinCount);

    // Create arrays to track audio impact values for smoother transitions
    const bassImpactHistory = new Array(3).fill(0);
    const highImpactHistory = new Array(3).fill(0);

    // Previous values for detecting sudden changes
    let prevBassAvg = 0;
    let prevHighAvg = 0;

    // Track time for animation
    let time = 0;

    const animate = () => {
      time += 0.01;

      if (analyser && isPlaying) {
        analyser.getByteFrequencyData(frequencyData);

        // Calculate average for different frequency ranges
        // Bass (low frequencies) - first 8% of frequencies
        const bassEnd = Math.floor(frequencyData.length * 0.08);
        const bassSum = frequencyData
          .slice(0, bassEnd)
          .reduce((sum, value) => sum + value, 0);
        const bassAvg = bassSum / bassEnd / 256;

        // Mids (mid frequencies) - next 42% of frequencies
        const midEnd = Math.floor(frequencyData.length * 0.5);
        const midSum = frequencyData
          .slice(bassEnd, midEnd)
          .reduce((sum, value) => sum + value, 0);
        const midAvg = midSum / (midEnd - bassEnd) / 256;

        // Highs (high frequencies) - remaining 50% of frequencies
        const highSum = frequencyData
          .slice(midEnd)
          .reduce((sum, value) => sum + value, 0);
        const highAvg = highSum / (frequencyData.length - midEnd) / 256;

        // Overall intensity
        const avg =
          frequencyData.reduce((sum, value) => sum + value, 0) /
          frequencyData.length;
        const intensity = THREE.MathUtils.clamp((avg / 256) * 1.5, 0.3, 1);

        // Calculate bass impact - detect sudden increases in bass
        const bassChange = Math.max(0, bassAvg - prevBassAvg);
        const bassImpact = THREE.MathUtils.clamp(bassChange * 8.0, 0, 1);

        // Calculate high frequency impact - detect sudden increases in highs
        const highChange = Math.max(0, highAvg - prevHighAvg);
        const highImpact = THREE.MathUtils.clamp(highChange * 8.0, 0, 1);

        // Update history arrays - shorter for more responsive movement
        bassImpactHistory.push(bassImpact);
        bassImpactHistory.shift();
        highImpactHistory.push(highImpact);
        highImpactHistory.shift();

        // Calculate smoothed impact values
        const smoothBassImpact =
          bassImpactHistory.reduce((a, b) => a + b, 0) /
          bassImpactHistory.length;
        const smoothHighImpact =
          highImpactHistory.reduce((a, b) => a + b, 0) /
          highImpactHistory.length;

        // Store current values for next frame
        prevBassAvg = bassAvg * 0.8 + prevBassAvg * 0.2; // Smoother transition
        prevHighAvg = highAvg * 0.8 + prevHighAvg * 0.2; // Smoother transition

        // Update shader uniforms with more balanced values
        material.uniforms.uTime.value = time;
        material.uniforms.uIntensity.value = intensity;
        material.uniforms.uBassIntensity.value = THREE.MathUtils.clamp(
          bassAvg * 3.0, // Reduced from 8.0 to 3.0 for less sensitivity
          0,
          1,
        );
        material.uniforms.uMidIntensity.value = THREE.MathUtils.clamp(
          midAvg * 2.5, // Reduced from 6.0 to 2.5
          0,
          1,
        );
        material.uniforms.uHighIntensity.value = THREE.MathUtils.clamp(
          highAvg * 3.0, // Reduced from 6.0 to 3.0
          0,
          1,
        );
        material.uniforms.uBassImpact.value = smoothBassImpact;
        material.uniforms.uHighImpact.value = smoothHighImpact;
      } else {
        // Gradually fade out when not playing
        material.uniforms.uTime.value = time;
        material.uniforms.uIntensity.value = Math.max(
          0.3,
          material.uniforms.uIntensity.value * 0.95,
        );
        material.uniforms.uBassIntensity.value *= 0.95;
        material.uniforms.uMidIntensity.value *= 0.95;
        material.uniforms.uHighIntensity.value *= 0.95;
        material.uniforms.uBassImpact.value *= 0.9;
        material.uniforms.uHighImpact.value *= 0.9;
      }

      renderer.render(scene, camera);
      frameId = requestAnimationFrame(animate);
    };

    animate();

    // Handle window resize
    const handleResize = () => {
      renderer.setSize(window.innerWidth, window.innerHeight);
      material.uniforms.uResolution.value.set(
        window.innerWidth,
        window.innerHeight,
      );
    };

    window.addEventListener("resize", handleResize);

    // Cleanup on component unmount
    return () => {
      cancelAnimationFrame(frameId);
      window.removeEventListener("resize", handleResize);
      if (mountRef.current) {
        mountRef.current.removeChild(renderer.domElement);
      }
      renderer.dispose();
    };
  }, [analyser, isPlaying]);

  return (
    <div
      ref={mountRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        zIndex: 4,
      }}
    />
  );
};

export default AudioVisualizer;
