import { useState, useEffect } from "react";
import "./App.css";
import Logo from "./components/Logo";
import FluidAnimation from "./components/FluidAnimation";
import FolderList from "./components/FolderList";
import CircularText from "./components/CircularText";
import TopAudioPlayer from "./components/TopAudioPlayer";
import AudioVisualizer from "./components/AudioVisualizer";
import { images } from "./components/FluidAnimation";
import { AudioProvider, useAudio } from "./context/AudioContext";

function App() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // Add this to check for the global audio visualizer props
  const [visualizerProps, setVisualizerProps] = useState<{
    analyser: AnalyserNode | null;
    isPlaying: boolean;
  }>({ analyser: null, isPlaying: false });

  // Check for updates to the global audio visualizer props
  useEffect(() => {
    const checkForVisualizerProps = () => {
      // @ts-ignore - Reading from window
      if (window.audioVisualizerProps) {
        // @ts-ignore
        setVisualizerProps(window.audioVisualizerProps);
      }
    };

    // Check immediately and then set up interval
    checkForVisualizerProps();
    const interval = setInterval(checkForVisualizerProps, 100);

    return () => clearInterval(interval);
  }, []);

  const handleRotateImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  return (
    <AudioProvider>
      <div className="App">
        <div className="mobile-warning">
          <h2>Please view on a computer</h2>
        </div>
        <TopAudioPlayer />
        <CircularText />
        <div className="hero-section">
          {/* Add the AudioVisualizer here, inside the hero section */}
          {visualizerProps.analyser && visualizerProps.isPlaying && (
            <AudioVisualizer
              analyser={visualizerProps.analyser}
              isPlaying={visualizerProps.isPlaying}
            />
          )}
          <FluidAnimation currentImageIndex={currentImageIndex} />
          <div className="mask mask-athens">
            <header className="App-header">
              <div className="logo-container">
                <Logo onRotateImage={handleRotateImage} />
              </div>
            </header>
          </div>
        </div>
        <div className="folder-section">
          <FolderList />
        </div>
      </div>
    </AudioProvider>
  );
}

export default App;
