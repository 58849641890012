import React, { useState, useRef, useEffect } from "react";
import { X } from "lucide-react";

interface ContentItemProps {
  name: string;
  dateAdded: string;
  contentUrl: string;
  kind: string;
  size: string;
}

const ContentItem: React.FC<ContentItemProps> = ({
  name,
  dateAdded,
  contentUrl,
  kind,
  size,
}) => {
  const [showModal, setShowModal] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Cleanup when component unmounts or modal closes
    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  }, [showModal]);

  const toggleModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (showModal && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    setShowModal(!showModal);
  };

  const handleModalClick = (e: React.MouseEvent) => {
    // Only close if clicking outside the content
    if (!modalContentRef.current?.contains(e.target as Node)) {
      toggleModal(e);
    }
  };

  const handleContentClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      <div className="folder-item nested-item" onClick={toggleModal}>
        <span className="name-column">{name}</span>
        <span>{dateAdded}</span>
        <span>{size}</span>
        <span>{kind}</span>
      </div>

      {showModal && (
        <div className="content-modal-overlay" onClick={handleModalClick}>
          <div
            ref={modalContentRef}
            className="content-modal"
            onClick={handleContentClick}
          >
            <button className="close-button" onClick={toggleModal}>
              <X size={24} />
            </button>
            {kind === "IMAGE" ? (
              <img src={contentUrl} alt={name} />
            ) : (
              <video ref={videoRef} controls>
                <source src={contentUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ContentItem;
