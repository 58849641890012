import React, { useState } from "react";
import { Folder, FolderOpen } from "lucide-react";
import { BaseItem } from "../types";
import { folders } from "../data/folderData";
import SongItem from "./folders/SongItem";
import ContentItem from "./folders/ContentItem";
import FolderAnimation from "./FolderAnimation";

const FolderList: React.FC = () => {
  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>(
    {},
  );

  const toggleFolder = (folderName: string) => {
    setOpenFolders((prev) => ({
      ...prev,
      [folderName]: !prev[folderName],
    }));
  };

  const renderItemName = (item: BaseItem, isNested: boolean) => {
    if (item.url) {
      const [role, company] = item.name.split(", ");
      return (
        <span style={item.font ? { fontFamily: item.font } : undefined}>
          {role},{" "}
          <a
            href={item.url}
            className="company-link"
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            rel="noopener noreferrer"
          >
            {company}
          </a>
        </span>
      );
    }

    return (
      <span style={item.font ? { fontFamily: item.font } : undefined}>
        {item.name}
      </span>
    );
  };

  const renderItem = (item: BaseItem, isNested: boolean = false) => {
    if (isNested && item.kind === "LINK") {
      return (
        <div
          key={item.name}
          className="folder-item nested-item"
          onClick={(e) => {
            e.stopPropagation();
            if (item.url) {
              window.open(item.url, "_blank", "noopener,noreferrer");
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <span className="name-column">
            <span style={item.font ? { fontFamily: item.font } : undefined}>
              {item.name}
            </span>
          </span>
          <span>{item.dateAdded}</span>
          <span>{item.size}</span>
          <span>{item.kind}</span>
        </div>
      );
    }

    if (isNested && item.kind === "AUDIO") {
      return (
        <SongItem
          key={item.name}
          name={item.name}
          dateAdded={item.dateAdded}
          audioUrl={item.audioUrl || ""}
        />
      );
    }

    if (isNested && (item.kind === "IMAGE" || item.kind === "VIDEO")) {
      return (
        <ContentItem
          key={item.name}
          name={item.name}
          dateAdded={item.dateAdded}
          contentUrl={item.contentUrl || ""}
          kind={item.kind}
          size={item.size}
        />
      );
    }

    if (item.Component) {
      return (
        <div className={`folder-item ${isNested ? "nested-item" : ""}`}>
          <span>{item.name}</span>
          <span>{item.dateAdded}</span>
          <span>{item.size}</span>
          <span>{item.kind}</span>
          <item.Component />
        </div>
      );
    }

    return (
      <div
        key={item.name}
        className={`folder-item ${isNested ? "nested-item" : ""}`}
        onClick={() => !isNested && toggleFolder(item.name)}
      >
        <span className="name-column">
          {!isNested && (
            <div className="folder-icon">
              {openFolders[item.name] ? (
                <FolderOpen size={20} />
              ) : (
                <Folder size={20} />
              )}
            </div>
          )}
          {renderItemName(item, isNested)}
        </span>
        <span>{item.dateAdded}</span>
        <span>{item.size}</span>
        <span>{item.kind}</span>
      </div>
    );
  };

  return (
    <div className="folder-list">
      <div className="folder-list-header">
        <span>NAME</span>
        <span>DATE ADDED</span>
        <span>SIZE</span>
        <span>KIND</span>
      </div>
      {folders.map((folder, index) => (
        <FolderAnimation key={folder.name} index={index}>
          <React.Fragment>
            {renderItem(folder)}
            {openFolders[folder.name] &&
              folder.items?.map((item) => renderItem(item, true))}
          </React.Fragment>
        </FolderAnimation>
      ))}
    </div>
  );
};

export default FolderList;
