import { BaseItem } from "../../types";

export const careerItems: BaseItem[] = [
  {
    name: "Software Engineer, Forum3",
    size: "∞",
    dateAdded: "10.2023 - Present",
    kind: "EXPERIENCE",
    url: "https://forum3.com",
  },
  {
    name: "Solution Engineer Intern, Sigma Computing",
    size: "∞",
    dateAdded: "08.2022 - 03.2023",
    kind: "EXPERIENCE",
  },
  {
    name: "Biomedical Informatics Intern, VU Medical Center",
    size: "∞",
    dateAdded: "06.2021 - 08.2021",
    kind: "EXPERIENCE",
  },
];
