import React from "react";
import { useAudio } from "../context/AudioContext";

const CircularText = () => {
  const { progress } = useAudio();

  // The text to display in the circular path
  const circularText =
    "Nothing was the same • Nothing was the same • Nothing was the same • Nothing was the same • Nothing was the same • Nothing was the same •";

  // Convert the text to an array of characters for individual styling
  const characters = circularText.split("");

  // Calculate how many characters should be highlighted based on progress
  const highlightedCount = Math.floor(progress * characters.length);

  return (
    <div className="circular-text-container">
      <svg viewBox="0 0 200 200" width="100%" height="100%">
        <defs>
          <path
            id="circle"
            d="M 100,100 m -150,0 a 150,150 0 1,1 300,0 a 150,150 0 1,1 -300,0"
          />
        </defs>
        <text className="circular-text rotating">
          <textPath href="#circle">
            {characters.map((char, index) => (
              <tspan
                key={index}
                fill={
                  index < highlightedCount
                    ? `rgba(255, 20, 147, ${Math.min(0.4, (highlightedCount - index) / 10)})`
                    : "rgba(102, 102, 102, 0.3)"
                }
              >
                {char}
              </tspan>
            ))}
          </textPath>
        </text>
      </svg>
    </div>
  );
};

export default CircularText;
