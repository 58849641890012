import React from "react";
import { ChevronDown, ChevronRight } from "lucide-react";

interface LogoProps {
  onRotateImage: () => void;
}

const Logo: React.FC<LogoProps> = ({ onRotateImage }) => {
  const scrollToFolders = () => {
    const currentPosition = window.scrollY;
    const targetPosition = currentPosition + window.innerHeight * 0.35;

    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="logo-wrapper"
      style={{ height: "100vh", position: "relative" }}
    >
      <div
        className="logo-text-container"
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "min(240px, 30vw)",
            fontWeight: "bold",
            color: "#000000",
            fontFamily: "Narnia, Arial, sans-serif",
            wordBreak: "break-word",
            lineHeight: "1.2",
          }}
        >
          JW
        </div>
        <button
          className="rotate-button"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translateY(-50%)",
            background: "white",
            border: "none",
            padding: "4px",
            opacity: "0.0", // changed from 0.6 to hide
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={onRotateImage}
        >
          <ChevronRight size={32} />
        </button>
      </div>
      <button className="scroll-button" onClick={scrollToFolders}>
        <ChevronDown size={16} />
      </button>
    </div>
  );
};

export default Logo;
