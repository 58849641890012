import { FolderData } from "../types";
import { careerItems } from "../components/folders/CareerFolder";
import { educationItems } from "../components/folders/EducationFolder";
import { songItems } from "../components/folders/SongFolder";
import { contentItems } from "../components/folders/ContentFolder";
import { contactItems } from "../components/folders/ContactFolder";
import { openThisItems } from "../components/folders/OpenThisFolder";
import { stockItems } from "../components/folders/StockFolder";

export const folders: FolderData = [
  {
    name: "EXPERIENCE",
    size: "4.5GB",
    dateAdded: "03.13.2019 11:11PM",
    kind: "FOLDER",
    items: careerItems,
  },
  {
    name: "EDUCATION",
    size: "3GB",
    dateAdded: "08.22.2024 01:22AM",
    kind: "FOLDER",
    items: educationItems,
  },
  {
    name: "CONTACT",
    size: "239MB",
    dateAdded: "02.14.2022 10:31PM",
    kind: "FOLDER",
    items: contactItems,
  },
  {
    name: "ON_REPEAT",
    size: "567MB",
    dateAdded: "08.22.2024 01:22AM",
    kind: "FOLDER",
    items: songItems,
  },
  {
    name: "PAST_FEW_SUMMERS",
    size: "137MB",
    dateAdded: "08.22.2024 01:22AM",
    kind: "FOLDER",
    items: contentItems,
  },
  {
    name: "OPEN_THIS_FOLDER",
    size: "360GB",
    dateAdded: "12.21.2021 12:21PM",
    kind: "FOLDER",
    items: openThisItems,
  },
  // {
  //     name: "PERFORMANCE",
  //     size: "1.2GB",
  //     dateAdded: "08.08.2022 09:00AM",
  //     kind: "FOLDER",
  //     items: stockItems,
  // },
  {
    name: "",
    size: "0MB",
    dateAdded: "02.28.2024 11:45PM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.01.2024 09:23AM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.12.2024 04:56PM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.07.2024 07:33AM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "02.25.2024 01:19PM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.18.2024 08:42PM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.05.2024 05:11AM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.09.2024 10:27PM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.14.2024 03:48AM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.02.2024 06:59PM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.11.2024 12:36PM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.16.2024 09:14AM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.04.2024 07:22PM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.08.2024 01:47AM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.03.2024 08:39PM",
    kind: "FOLDER",
    items: [],
  },
  {
    name: "",
    size: "0MB",
    dateAdded: "03.10.2024 06:15PM",
    kind: "FOLDER",
    items: [],
  },
];
