import { BaseItem } from "../../types";

export const openThisItems: BaseItem[] = [
  {
    name: "Please contact me if you'd like a website",
    size: "360GB",
    dateAdded: "12.21.2021 12:21PM",
    kind: "TEXT",
  },
];
