import { BaseItem } from "../../types";

export const songItems: BaseItem[] = [
  {
    name: "Liability.mp3",
    size: "",
    dateAdded: "",
    kind: "AUDIO",
    audioUrl: "/songs/Drake_-_Liability.mp3",
  },
  {
    name: "NOKIA.mp3",
    size: "",
    dateAdded: "",
    kind: "AUDIO",
    audioUrl: "/songs/NOKIA.mp3",
  },
  {
    name: "Passionfruit.mp3",
    size: "",
    dateAdded: "",
    kind: "AUDIO",
    audioUrl: "/songs/Passionfruit.mp3",
  },
  {
    name: "Darling_I.mp3",
    size: "",
    dateAdded: "",
    kind: "AUDIO",
    audioUrl: "/songs/Darling_I.mp3",
  },
  {
    name: "I_Love_You.mp3",
    size: "",
    dateAdded: "",
    kind: "AUDIO",
    audioUrl: "/songs/I_Love_You.mp3",
  },
  {
    name: "lEgs_In_tHe_aIr.mp3",
    size: "",
    dateAdded: "",
    kind: "AUDIO",
    audioUrl: "/songs/lEgs_In_tHe_aIr.mp3",
  },
];
